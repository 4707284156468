import { augmentedOccasionsApi } from 'api'
import { ChartScoreDto } from 'types/dto/chartsReportDto'

export interface OverallCognitiveImpactProps {
  sitecoreId: string
  channelId: string
  zoneId: string
}

export const fetchOverallCognitiveImpact = ({ sitecoreId, channelId, zoneId }: OverallCognitiveImpactProps) =>
  augmentedOccasionsApi.get<ChartScoreDto>(
    `/report/sitecore/${sitecoreId}/channel/${channelId}/zone/${zoneId}/cognitive-impact`,
  )
