import {
  WppTypography,
  // WppButton,
  WppDivider,
  WppBreadcrumb,
  WppSpinner,
} from '@platform-ui-kit/components-library-react'
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'

// import miroLogoSrc from 'assets/miro-logo.svg'
import { AdsAndAssets } from 'components/augmentedOccasionsResult/adsAndAssets/AdsAndAssets'
import styles from 'components/augmentedOccasionsResult/AugmentedOccasionsResult.module.scss'
import { BriefSection } from 'components/augmentedOccasionsResult/briefSection/BriefSection'
import { Creatives } from 'components/augmentedOccasionsResult/creatives/Creatives'
import { Inspirations } from 'components/augmentedOccasionsResult/inspirations/Inspirations'
import { OccasionsResult } from 'components/augmentedOccasionsResult/occasionsResult/OccasionsResult'
import { Flex } from 'components/common/flex/Flex'
import { useAugmentedOccasions } from 'hooks/useAugmentedOccasions'
import { OccasionItemDto } from 'types/dto/augmentedOccasionsDto'

export const AugmentedOccasionsResult = () => {
  const { t } = useTranslation()
  let { id: occasionId } = useParams()
  const navigate = useNavigate()
  const { savedOccasions, savedOccasionsIsLoading } = useAugmentedOccasions()

  const occasion = useMemo(() => {
    if (!savedOccasions) return undefined
    return savedOccasions.find(occasion => occasion.id === occasionId)
  }, [savedOccasions, occasionId])

  const breadcrumbItems = useMemo(() => {
    if (!occasion)
      return [
        {
          label: t('augmented_occassions'),
          path: '/',
        },
      ]

    return [
      {
        label: t('augmented_occassions'),
        path: '/',
      },

      {
        label: occasion.name,
        path: `/${occasion.id}`,
      },
    ]
  }, [occasion, t])

  const [selectedOccasion, setSelectedOccasion] = useState<OccasionItemDto | null>(null)

  const handleRouteChange = (event: CustomEvent) => {
    navigate(event.detail.path)
  }

  if (savedOccasionsIsLoading) {
    return (
      <Flex justify="center" align="center" className={styles.loadingSpinnerContainer}>
        <WppSpinner size="l" />
      </Flex>
    )
  }

  if (!occasion) {
    return (
      <div className={styles.resultsHeader}>
        <WppBreadcrumb
          items={breadcrumbItems}
          middleTruncation
          onWppChange={handleRouteChange}
          className={styles.breadcrumbs}
        />
        <WppTypography type="3xl-heading" tag="h1">
          {t('occasion_not_found')}
        </WppTypography>
      </div>
    )
  }

  return (
    <>
      <div className={styles.resultsHeader}>
        <WppBreadcrumb
          items={breadcrumbItems}
          middleTruncation
          onWppChange={handleRouteChange}
          className={styles.breadcrumbs}
        />
        <Flex justify="between" gap={24}>
          <WppTypography type="3xl-heading" tag="h1">
            {occasion.name}
          </WppTypography>
          {/* TODO implement create miro */}
          {/* <WppButton className={styles.miroButton}>
            <img src={miroLogoSrc} alt="Miro Logo" slot="icon-start" />
            {t('create_miro')}
          </WppButton> */}
        </Flex>
        <WppTypography type="m-body" className={styles.prompt}>
          {occasion.prompt}
        </WppTypography>
        {/* <br /> */}
        {/* <WppTypography type="2xs-strong">Coca-Cola</WppTypography> TODO show brand */}
      </div>
      <WppDivider />
      <BriefSection occasions={occasion.occasions} />
      <WppDivider />
      <OccasionsResult
        countryCode={occasion.country}
        occasions={occasion.occasions}
        onSelectOccasion={occasion => setSelectedOccasion(occasion)}
      />
      <WppDivider />
      {!!selectedOccasion && <Inspirations inspirationsQuery={selectedOccasion.auotCode} />}
      <WppDivider />
      {!!selectedOccasion && <AdsAndAssets occasion={selectedOccasion} />}
      {!!selectedOccasion && <Creatives occasion={selectedOccasion} />}
    </>
  )
}
