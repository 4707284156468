import { augmentedOccasionsApi } from 'api'
import { InspirationDocumentDto } from 'types/dto/augmentedOccasionsDto'

export interface InspirationsParams {
  query: string
  tenantName: string
  tenant_id: string
  documentsPerPage: number
}

export const fetchInspirations = ({ query, tenantName, tenant_id, documentsPerPage }: InspirationsParams) =>
  augmentedOccasionsApi.get<InspirationDocumentDto[]>('/documents', {
    params: { query, tenantName, tenant_id, documentsPerPage },
  })
