import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'components/augmentedOccasionsResult/inspirations/inspirationListCardItem/InspirationListCardItem.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { InspirationDocumentDto } from 'types/dto/augmentedOccasionsDto'

interface Props {
  document: InspirationDocumentDto
}

export const InspirationListCardItem = ({ document }: Props) => {
  return (
    <a href={`${document.filePath}${document.token}`} target="_blank" rel="noreferrer" className={styles.documentCard}>
      <img alt="" src={`${document.thumbnailPath}${document.token}`} />
      <Flex direction="column" justify="between" className={styles.cardContent}>
        <div>
          <div>
            {/* TODO API is not returning this */}
            <WppTypography tag="p" type="2xs-strong">
              Case studies
            </WppTypography>
          </div>
          <div>
            <WppTypography tag="p" type="m-strong" className={styles.documentTitle}>
              {document.title}
            </WppTypography>
          </div>
        </div>
        <Flex gap={8} className={styles.tagsWrapper} wrap="wrap">
          {document.tags.slice(0, 5).map((tag: string, index: number) => (
            <span className={styles.tag} key={index}>
              <WppTypography tag="span" type="s-midi" className={styles.tagText}>
                {tag}
              </WppTypography>
            </span>
          ))}
        </Flex>
      </Flex>
    </a>
  )
}
