import { WppTypography, WppCard } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/augmentedOccasionsResult/briefSection/BriefSection.module.scss'
import { AugmentedOccasionDto } from 'types/dto/augmentedOccasionsDto'

interface Props {
  occasions: AugmentedOccasionDto[]
}

export const BriefSection = ({ occasions }: Props) => {
  const { t } = useTranslation()

  const briefResult = useMemo(() => {
    const result: { [key: string]: string } = {}

    occasions.forEach(occasion => {
      result[occasion.name] = occasion.items
        .map(item => {
          if (!item.auotCode) return ''
          return item.auotCode.charAt(0).toUpperCase() + item.auotCode.slice(1)
        })
        .join(', ')
    })

    return result
  }, [occasions])

  return (
    <div className={styles.briefSection}>
      <WppTypography type="2xl-heading" tag="h2" className={styles.briefTitle}>
        {t('brief_section_title')}
      </WppTypography>
      <WppCard className={styles.briefCard}>
        <div className={styles.briefCardSection}>
          <div>
            <WppTypography type="l-strong" tag="h3" className={styles.briefCardSectionTitle}>
              {t('experience_occasions')}
            </WppTypography>
          </div>
          <WppTypography type="s-body">{briefResult['Experience occasions']}</WppTypography>
        </div>
        <div className={styles.briefCardSection}>
          <div>
            <WppTypography type="l-strong" tag="h3" className={styles.briefCardSectionTitle}>
              {t('buy_occasions')}
            </WppTypography>
          </div>
          <WppTypography type="s-body">{briefResult['Buy occasions']}</WppTypography>
        </div>
        <div>
          <div>
            <WppTypography type="l-strong" tag="h3" className={styles.briefCardSectionTitle}>
              {t('use_occasions')}
            </WppTypography>
          </div>
          <WppTypography type="s-body">{briefResult['Use occasions']}</WppTypography>
        </div>
      </WppCard>
    </div>
  )
}
