// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hOCs5{display:block;overflow:hidden;background-color:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-m);box-shadow:var(--wpp-box-shadow-s)}.hOCs5 img{width:100%;transition:transform .5s ease;aspect-ratio:219/142;object-fit:cover}.hOCs5:hover{box-shadow:var(--wpp-box-shadow-m)}.hOCs5:hover img{transform:scale(1.05)}.R9hlk{padding:12px 12px 20px}.XsjcE{padding-top:6px}.ZPboE{padding-top:12px}.d6H67{padding:1px 8px;background-color:var(--wpp-grey-color-300);border-radius:var(--wpp-border-radius-xs)}.W7KlM{color:var(--wpp-grey-color-800)}", "",{"version":3,"sources":["webpack://./src/components/augmentedOccasionsResult/inspirations/inspirationListCardItem/InspirationListCardItem.module.scss"],"names":[],"mappings":"AAAA,OACE,aAAA,CACA,eAAA,CACA,0CAAA,CACA,wCAAA,CACA,kCAAA,CAEA,WACE,UAAA,CACA,6BAAA,CACA,oBAAA,CACA,gBAAA,CAGF,aACE,kCAAA,CAEA,iBACE,qBAAA,CAKN,OACE,sBAAA,CAGF,OACE,eAAA,CAGF,OACE,gBAAA,CAGF,OACE,eAAA,CACA,0CAAA,CACA,yCAAA,CAGF,OACE,+BAAA","sourcesContent":[".documentCard {\n  display: block;\n  overflow: hidden;\n  background-color: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-m);\n  box-shadow: var(--wpp-box-shadow-s);\n\n  & img {\n    width: 100%;\n    transition: transform 0.5s ease;\n    aspect-ratio: 219 / 142;\n    object-fit: cover;\n  }\n\n  &:hover {\n    box-shadow: var(--wpp-box-shadow-m);\n\n    & img {\n      transform: scale(1.05);\n    }\n  }\n}\n\n.cardContent {\n  padding: 12px 12px 20px;\n}\n\n.documentTitle {\n  padding-top: 6px;\n}\n\n.tagsWrapper {\n  padding-top: 12px;\n}\n\n.tag {\n  padding: 1px 8px;\n  background-color: var(--wpp-grey-color-300);\n  border-radius: var(--wpp-border-radius-xs);\n}\n\n.tagText {\n  color: var(--wpp-grey-color-800);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentCard": "hOCs5",
	"cardContent": "R9hlk",
	"documentTitle": "XsjcE",
	"tagsWrapper": "ZPboE",
	"tag": "d6H67",
	"tagText": "W7KlM"
};
export default ___CSS_LOADER_EXPORT___;
