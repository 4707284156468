import { WppTypography, WppCard, WppSpinner } from '@platform-ui-kit/components-library-react'
import * as echarts from 'echarts'
import { useEffect, useRef, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchOverallCognitiveImpact } from 'api/augmentedOccasions/queries/useFetchOverallCognitiveImpact'
import styles from 'components/augmentedOccasionsResult/adsAndAssets/overallCognitiveImpactScore/OverallCognitiveImpactScore.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { sitecoreId } from 'constants/sitecore'
import { useResizeObserver } from 'hooks/useResizeObserver'
import { OccasionItemDto } from 'types/dto/augmentedOccasionsDto'

interface Props {
  occasion: OccasionItemDto
}

export const OverallCognitiveImpactScore = ({ occasion }: Props) => {
  const { t } = useTranslation()
  const { data, isLoading } = useFetchOverallCognitiveImpact({
    params: {
      sitecoreId: sitecoreId,
      channelId: occasion.aochId,
      zoneId: occasion.aozoId,
    },
  })

  const [chartWidth, setChartWidth] = useState(600)

  const onResize = useCallback((target: HTMLDivElement) => {
    setChartWidth(target.clientWidth - 32)
  }, [])

  const chartPie = useRef(null)
  const chartWrapperRef = useResizeObserver(onResize)

  useEffect(() => {
    if (!chartPie.current) return

    var myChart = echarts.init(chartPie.current)
    myChart.resize({
      width: chartWidth,
      height: 400,
    })

    if (data) {
      var option

      option = {
        color: ['#1FBC59', '#EAB308', '#F87171'],
        legend: {
          orient: 'vertical',
          right: 10,
          top: 50,
          icon: 'circle',
        },
        series: [
          {
            type: 'pie',
            label: {
              show: false,
            },
            data: [
              {
                value: data.HIGH,
                name: 'High',
              },
              {
                value: data.MEDIUM,
                name: 'Medium',
              },
              {
                value: data.LOW,
                name: 'Low',
              },
            ],
          },
        ],
      }

      myChart.setOption(option)
    }
  }, [chartPie, chartWidth, data])

  return (
    <WppCard className={styles.chartCard}>
      <div slot="header">
        <WppTypography type="m-strong">{t('ads_and_assets.overall_congitive_impact_score')}</WppTypography>
      </div>
      <div className={styles.chartWrapper} ref={chartWrapperRef}>
        {isLoading ? (
          <Flex justify="center" align="center" className={styles.spinnerWrapper}>
            <WppSpinner size="m" />
          </Flex>
        ) : (
          <div ref={chartPie} />
        )}
      </div>
    </WppCard>
  )
}
