import { augmentedOccasionsApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
import { CreateScoreImpactReportBody, ScoreImpactReportResponseDto } from 'types/dto/chartsReportDto'

export interface Props {
  sitecoreId: string
  body: CreateScoreImpactReportBody
}

export const createScoreImpactReport = ({
  sitecoreId,
  body,
}: Props): CancelableRequestProducer<ScoreImpactReportResponseDto> =>
  augmentedOccasionsApi.post(`/report/sitecore/${sitecoreId}/score-impact`, body)
