import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'

import styles from 'components/augmentedOccasionsResult/occasionsResult/occasionListItem/OccasionListItem.module.scss'
import { OccasionItemDto } from 'types/dto/augmentedOccasionsDto'

interface Props {
  index: number
  occasion: OccasionItemDto
  isSelected: boolean
  onSelectItem: (index: number) => void
}

export const OccasionListItem = ({ index, occasion, isSelected, onSelectItem }: Props) => {
  const occasionTitle = useMemo(() => {
    if (!occasion.auotCode) return ''
    return occasion.auotCode.charAt(0).toUpperCase() + occasion.auotCode.slice(1)
  }, [occasion.auotCode])

  return (
    <div
      className={clsx(styles.tabResultsItem, isSelected && styles.tabResultsItemActive)}
      onClick={() => onSelectItem(index)}
    >
      <div>
        <WppTypography type="s-body" className={clsx(isSelected && styles.selectedTextColor)}>
          {occasionTitle}
        </WppTypography>
      </div>
      <div>
        <WppTypography
          type="s-body"
          className={clsx(styles.tabResultsSubtitle, isSelected && styles.selectedTextColor)}
        >
          {`${occasion.aoch.aochCode} / ${occasion.aozo.aozoCode}`}
        </WppTypography>
      </div>
    </div>
  )
}
