import { SegmentedControlChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppSegmentedControlCustomEvent } from '@platform-ui-kit/components-library/loader'
import { WppTypography, WppSegmentedControl, WppSegmentedControlItem } from '@platform-ui-kit/components-library-react'
import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { countryVisualisationsMock } from 'components/augmentedOccasionsResult/occasionsResult/countryVisualisationsMock'
import { OccasionListItem } from 'components/augmentedOccasionsResult/occasionsResult/occasionListItem/OccasionListItem'
import styles from 'components/augmentedOccasionsResult/occasionsResult/OccasionsResult.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { AugmentedOccasionDto, CountryCode, OccasionItemDto } from 'types/dto/augmentedOccasionsDto'

export enum OccasionsTabs {
  EXPERIENCE = 'EXPERIENCE',
  BUY = 'BUY',
  USE = 'USE',
}

interface Props {
  countryCode: CountryCode
  occasions: AugmentedOccasionDto[]
  onSelectOccasion: (occasion: OccasionItemDto) => void
}

export const OccasionsResult = ({ countryCode, occasions, onSelectOccasion }: Props) => {
  const { t } = useTranslation()

  const [initialOccasionWasSet, setInitialOccasionWasSet] = useState(false)
  const [selectedTab, setSelectedTab] = useState<OccasionsTabs>(OccasionsTabs.EXPERIENCE)
  const [selectedOccasions, setSelectedOccasions] = useState({
    [OccasionsTabs.EXPERIENCE]: 0,
    [OccasionsTabs.BUY]: 0,
    [OccasionsTabs.USE]: 0,
  })

  const visualisationUrl = useMemo(() => {
    const visualisationId = selectedOccasions[selectedTab] < 5 ? selectedOccasions[selectedTab] : 0
    return countryVisualisationsMock[countryCode][visualisationId]
  }, [countryCode, selectedTab, selectedOccasions])

  useEffect(() => {
    if (!initialOccasionWasSet) {
      onSelectOccasion(occasions[0].items[0])
      setInitialOccasionWasSet(true)
    }
  }, [initialOccasionWasSet, occasions, onSelectOccasion])

  const handleTabChange = (event: WppSegmentedControlCustomEvent<SegmentedControlChangeEventDetail>) => {
    const tab = event.detail.value as OccasionsTabs
    setSelectedTab(tab)
    onSelectOccasion(
      occasions[tab === OccasionsTabs.EXPERIENCE ? 0 : tab === OccasionsTabs.BUY ? 1 : 2].items[selectedOccasions[tab]],
    )
  }

  const handleSelectOccasion = (occasionTab: OccasionsTabs, occasionIndex: number) => {
    setSelectedOccasions(prevState => ({ ...prevState, [occasionTab]: occasionIndex }))
    const selectedTabIndex = occasionTab === OccasionsTabs.EXPERIENCE ? 0 : occasionTab === OccasionsTabs.BUY ? 1 : 2
    onSelectOccasion(occasions[selectedTabIndex].items[occasionIndex])
  }

  const renderOccasionItems = (occasionTab: OccasionsTabs) => {
    return occasions[
      occasionTab === OccasionsTabs.EXPERIENCE ? 0 : occasionTab === OccasionsTabs.BUY ? 1 : 2
    ].items.map((item, index) => (
      <OccasionListItem
        index={index}
        occasion={item}
        isSelected={selectedOccasions[occasionTab] === index}
        onSelectItem={() => handleSelectOccasion(occasionTab, index)}
        key={index}
      />
    ))
  }

  return (
    <div>
      <WppTypography type="2xl-heading" tag="h2" className={styles.title}>
        {t('occasions_section_title')}
      </WppTypography>
      <div>
        <WppSegmentedControl value={selectedTab} size="s" onWppChange={handleTabChange}>
          <WppSegmentedControlItem value={OccasionsTabs.EXPERIENCE}>
            {t('experience_occasions')}
          </WppSegmentedControlItem>
          <WppSegmentedControlItem value={OccasionsTabs.BUY}>{t('buy_occasions')}</WppSegmentedControlItem>
          <WppSegmentedControlItem value={OccasionsTabs.USE}>{t('use_occasions')}</WppSegmentedControlItem>
        </WppSegmentedControl>
      </div>
      <Flex justify="between" gap={24} className={styles.tabResultsWrapper}>
        <div className={styles.tabResults}>
          {selectedTab === OccasionsTabs.EXPERIENCE && renderOccasionItems(OccasionsTabs.EXPERIENCE)}
          {selectedTab === OccasionsTabs.BUY && renderOccasionItems(OccasionsTabs.BUY)}
          {selectedTab === OccasionsTabs.USE && renderOccasionItems(OccasionsTabs.USE)}
        </div>
        <iframe src={visualisationUrl} title="Occasion Visualisation" className={styles.occasionVisualisation} />
      </Flex>
    </div>
  )
}
