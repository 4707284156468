import { WppTypography, WppCard, WppToggle } from '@platform-ui-kit/components-library-react'
import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useCreateScoreImpactReport } from 'api/augmentedOccasions/mutations/useCreateScoreImpactReport'
import { ImpactBarChart } from 'components/augmentedOccasionsResult/adsAndAssets/impactScoreWithTouchpoints/impactBarChart/ImpactBarChart'
import styles from 'components/augmentedOccasionsResult/adsAndAssets/impactScoreWithTouchpoints/ImpactScoreWithTouchpoints.module.scss'
import { TouchpointsFilterModal } from 'components/augmentedOccasionsResult/adsAndAssets/impactScoreWithTouchpoints/touchpointsFilterModal/TouchpointsFilterModal'
import { Flex } from 'components/common/flex/Flex'
import { sitecoreId } from 'constants/sitecore'
import { Touchpoint, ChartScoreDto } from 'types/dto/chartsReportDto'

// sending no touchpoints in API request doesn't work -> if touchpoints are not selected, use all possible touchpoints
const allTouchpoints: Touchpoint[] = [
  {
    channelId: 'f99c775d-7ead-4de0-a533-4b29b96581bf',
    zoneId: '9100f3cc-e894-4b31-ad11-80cc7e655244',
  },
  {
    channelId: 'f99c775d-7ead-4de0-a533-4b29b96581bf',
    zoneId: '5cd61791-6acb-4d53-972f-315739f04679',
  },
  {
    channelId: 'f99c775d-7ead-4de0-a533-4b29b96581bf',
    zoneId: 'd0f5783b-4304-4182-bd10-4eba5ae4df40',
  },
  {
    channelId: 'f99c775d-7ead-4de0-a533-4b29b96581bf',
    zoneId: '274edf2b-c174-4485-8c18-7a34f0383673',
  },
  {
    channelId: 'ec40e5da-92d4-4382-8702-6f6b4f33ffc6',
    zoneId: 'd901a29b-c718-4253-b72b-64f2fb65932a',
  },
  {
    channelId: 'ec40e5da-92d4-4382-8702-6f6b4f33ffc6',
    zoneId: '08197047-f31f-4d5f-9348-b460c298f5ca',
  },
  {
    channelId: 'ec40e5da-92d4-4382-8702-6f6b4f33ffc6',
    zoneId: 'a688e79b-c073-47de-a7f9-5551730cf306',
  },
  {
    channelId: 'ce4a906f-9f0d-4efc-b3db-ee875e04aa18',
    zoneId: 'eb81f882-0942-4c9a-a3a7-3b0c020ef1ec',
  },
  {
    channelId: 'ce4a906f-9f0d-4efc-b3db-ee875e04aa18',
    zoneId: '44750235-e01c-449b-84a1-0dcabea24a1b',
  },
  {
    channelId: 'ce4a906f-9f0d-4efc-b3db-ee875e04aa18',
    zoneId: '54af809a-8385-4a21-8f35-becf7b29a565',
  },
]

export const ImpactScoreWithTouchpoints = () => {
  const { t } = useTranslation()
  const { mutateAsync: createScoreImpactReportMutation } = useCreateScoreImpactReport()

  const [accountForTouchpointsIsChecked, setAccountForTouchpointsIsChecked] = useState(false)
  const [selectedTouchpoints, setSelectedTouchpoints] = useState<Touchpoint[]>(allTouchpoints)
  const [visualImpactScore, setVisualImpactScore] = useState<ChartScoreDto | null>(null)
  const [cognitiveImpactScore, setCognitiveImpactScore] = useState<ChartScoreDto | null>(null)

  const handleFilterTouchpoints = (touchpoints: Touchpoint[]) => {
    if (touchpoints.length === 0) {
      setSelectedTouchpoints(allTouchpoints)
    }
    setSelectedTouchpoints(touchpoints)
  }

  const createScoreImpactReport = async (accountForTouchpointsIsChecked: boolean) => {
    try {
      const response = await createScoreImpactReportMutation({
        sitecoreId: sitecoreId,
        body: {
          intendedTouchpointOnly: accountForTouchpointsIsChecked,
          touchpoints: selectedTouchpoints,
        },
      })
      const { visualImpact, cognitiveImpact } = response.data
      setVisualImpactScore(visualImpact)
      setCognitiveImpactScore(cognitiveImpact)
    } catch (e) {
      console.error(e)
    }
  }

  const createScoreImpactReportCallback = useCallback(createScoreImpactReport, [
    createScoreImpactReportMutation,
    selectedTouchpoints,
  ])

  useEffect(() => {
    createScoreImpactReportCallback(accountForTouchpointsIsChecked)
  }, [createScoreImpactReportCallback, accountForTouchpointsIsChecked, selectedTouchpoints])

  return (
    <WppCard className={styles.card}>
      <div slot="header">
        <WppTypography type="m-strong">{t('ads_and_assets.impact_score_for_selected_touchpoints')}</WppTypography>
      </div>
      <Flex>
        <WppToggle
          required
          labelConfig={{ text: t('ads_and_assets.touchpoints_toggle_label') }}
          checked={accountForTouchpointsIsChecked}
          onWppChange={({ detail: { checked } }) => setAccountForTouchpointsIsChecked(checked)}
          className={styles.toggle}
        />
      </Flex>
      <TouchpointsFilterModal onSelectTouchpoints={handleFilterTouchpoints} />
      <Flex>
        {visualImpactScore && (
          <ImpactBarChart title={t('ads_and_assets.visual_impact')} chartData={visualImpactScore} />
        )}
        {cognitiveImpactScore && (
          <ImpactBarChart title={t('ads_and_assets.cogntitive_impact')} chartData={cognitiveImpactScore} />
        )}
      </Flex>
    </WppCard>
  )
}
